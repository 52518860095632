export const menuItemsData = [
  {
    title: "Home",
    url: "/",
    needsAuthentication: false,
  },
  {
    title: "Our Branch",
    url: "/branch",
    needsAuthentication: false,
    submenu: [
      {
        title: "Committees",
        url: "committees",
        needsAuthentication: false,
      },
      {
        title: "Membership",
        url: "membership",
        needsAuthentication: false,
      },
      {
        title: "Bursaries",
        url: "bursaries",
        needsAuthentication: false,
      },
      {
        title: "History",
        url: "history",
        needsAuthentication: false,
      },
    ],
  },
  {
    title: "Events",
    url: "/events",
    needsAuthentication: false,
    submenu: [
      {
        title: "Meat Draw",
        url: "meatdraw",
        needsAuthentication: false,
      },
/*      {
        title: "BBQ Event",
        url: "BBQ",
        needsAuthentication: false,
      },
*/
      ],
  },
  {
    title: "Remembrance",
    url: "/poppy",
    needsAuthentication: false,
  },
  {
    title: "Contact",
    url: "/contact",
    needsAuthentication: false,
  },
  {
    title: "Executive",
    url: "/executive",
    needsAuthentication: true,
  },
];
