import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import DLogo from '../assets/LegionLarge.png';
import MLogo from '../assets/LegionSmall.png';

const Header = () => {
    return (
        <header>
            <div className="nav-area">
                <nav className="desktop-nav">
                    <Link to="/" className="logo">
                        <img src={DLogo} alt="logo" style={{ width: '200px', }} />
                    </Link>
                </nav>
                <nav className="mobile-nav">
                    <Link to="/" className="logo">
                        <img src={MLogo} alt="home" style={{ width: '40px', }} />
                    </Link>
                </nav>
                {/* for large screens */}
                <Navbar />

                {/* for small screens */}
                <MobileNav />
            </div>
        </header>
    );
};

export default Header;