import React from "react";

const Events = () => {
  return (
    <div>
      <h1 className="text-rose-700">Branch Events</h1>
      <br />
      <br />
      <div >
        <p>January 21, 2024 - Gerneral Meeting.</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>February 25, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>March 17, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>April 21, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>May 26, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>June 23, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>September 15, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>October 20, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>November 11, 2024 - Remembrance Day</p>
        <p style={{ paddingLeft: 50 }}>
        </p>
        <p>November 17, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
        <p>December 15, 2024 - Gerneral Meeting</p>
        <p style={{ paddingLeft: 50 }}>
          2pm at the Shoal Centre (10030 Resthaven Dr)
        </p>
      </div>
    </div>
  );
};

export default Events;
