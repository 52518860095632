import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";

export default function Root() {
  return (
    <div className="min-h-screen">
      <Header />
      <div className="content flex-grow">
        <Outlet />
      </div>

      <Footer />
      <MobileFooter />
    </div>
  );
}
