import React from "react";

const BranchEvent = () => {
  return (
    <div>
      <h1 className="text-rose-700">Branch Events</h1>
      <br />
      <p>Branch Events</p>
      <br />
      January 21, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      February 25, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      March 17, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      April 21, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      May 19, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030 Resthaven
      Dr)
      <br />
      June 23, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030 Resthaven
      Dr)
      <br />
      September 15, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      October 20, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      November 17, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
      December 15, 2024 - Gerneral Meeting. 2pm at the Shoal Centre (10030
      Resthaven Dr)
      <br />
    </div>
  );
};

export default BranchEvent;
