import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faXTwitter,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer className="desktop-nav">
      <div className="flex sticky top-[100vh] z-100  justify-evenly items-center  w-full mx-auto px-2 sm:px-6 lg:px-8 border-t-4 border-rose-700 bg-rose-700 text-white ">
        <div className="flex flex-row mt-4 p-5  justify-center   sm:justify-center md:mt-0  ">
          <div className="m-2"></div>
          <div className="m-2">
            <a
              href="https://www.facebook.com/PeninsulaLegion"
              target="_blank"
              rel="noreferrer"
              className=" hover:text-white"
            >
              <FontAwesomeIcon icon={faSquareFacebook} size="8x" />{" "}
            </a>
           </div>
          <div className="m-2">
            <a
              href="https://twitter.com/peninsulalegion"
              target="_blank"
              rel="noreferrer"
              className=" hover:text-white"
            >
              <FontAwesomeIcon icon={faXTwitter} size="8x" />{" "}
            </a>
          </div>
          <div className="m-2">
            <a
              href="https://www.instagram.com/peninsulalegion/"
              target="_blank"
              rel="noreferrer"
              className=" hover:text-white"
            >
              <FontAwesomeIcon icon={faInstagramSquare} size="8x" />{" "}
            </a>
          </div>
        </div>
        <div className=" place-content-end mt-4 p-5 sm:justify-center md:mt-0 ">
          <p>Telephone: 250-656-2428</p>
          <p>
            General E-mail Inquiries:
            <span> </span>
            <a className="text-white" href="mailto:rcl37@peninsulalegion.ca">
              rcl37@peninsulalegion.ca
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>Royal Canadian Legion Branch #37</p>
          <p> P.O. Box 2152 STN MAIN</p>
          <p>Sidney, British Columbia V8L 3S6</p>
        </div>
      </div>
    </footer>
  );
}
