import React from "react";

const History = () => {
  return(
    <div className="">
      <h1 className="text-rose-700">Branch History</h1>
<br />
		<p>In 1925, Alan Calvert, a Veteran of the First World War and highly regarded in the community, called on other Veterans in the Saanich Peninsula and the Gulf Islands to join him at a meeting to be held in Matthews Hall in Sidney. At the meeting, Sir Percy Lake spoke on the advantages of joining the newly-organized Canadian Legion of the British Empire Service League. Sir Percy Lake was the Charter President, Dominion President, of the new Canadian Legion, and he lived in Victoria.</p>
    <br />
		<p>At the meeting, the decision was made to form a Branch of the Canadian Legion in this area. Alan Calvert immediately made application to the recently authorized Provincial Command office in Vancouver for a charter to be issued in the name of the Sidney branch, appending a list of names of those Veterans in agreement at this inaugural meeting.</p>
    <br />
		<p>Lieutenant-General Sir Percy Henry Noel Lake KCB KCMG was born in 1855 in England. His mother was from Quebec City. He served in the British Army in Afghanistan, Sudan, Egypt, and Ireland, before becoming Chief of the Canadian General Staff in 1905 and Inspector General of the Canadian Militia. He later served as Chief of General Staff in India, and led an expeditionary force in the Middle East during World War I. After the War, he retired to Victoria, dying there in 1940. A Scholarship was established in memory of him and his service to the South Vancouver Island region. </p>
    <br />
		<p>Alan Calvert, in essence the founder of our Branch, served during World War I. As a leader in the Legion, he was later elected as Zone Representative to Provincial Command. He was active in the local Saanich communities, in local politics, Chamber of Commerce, and his Church. He worked at the Saanich Peninsula Experimental Farm. Alan again answered the call of his country as World War II began, serving as a Warrant Officer II in the Royal Canadian Army Medical Corps (RCAMC). After the War, having been transferred to work in the Okanagan, he transferred to the West Summerland Legion Branch, where he continued to serve actively. After retirement, Alan returned to Sidney where he soon became the Honorary Secretary-Treasurer of Branch 37. He died at the age of 82, still active in the Legion. A <a href="bursaries.html" target="blank"> Scholarship</a> was established in memory of him and his service to our Branch and community.</p>
    <br />
		<p>Back in 1926, in due course, a Branch Charter in the name of the Sidney Branch was issued by the Dominion Command in Ottawa. Of the original members, who had paid their dues at that first meeting as a commitment, some continued to pay their dues for the next 50 years or more. In the early days, the Branch had a variety of meeting places, though for a long time meetings were held at the Hall of the Orangemens' Fraternal Order in Saanichton.</p>
    <br />
		<p>The Branch was one of several (later thirteen) branches organized into a Zone. The Branch's area of responsibility ranged from the Royal Oak region to the northern tip of the Saanich Peninsula. Alan Calvert made application to Dominion Command for a name change to the Charter to reflect this expansion. The official name of our Branch then became, and continues as, "The Saanich Peninsula Branch #37".</p>
		<br />
		<p> This page is under further development.</p>
    </div>
    );
};

export default History;