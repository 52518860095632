import React from "react";

const Meatdraw = () => {
  return (
    <div className="m-4 p-4">
      <h1 className="text-rose-700">Saanich Peninsula Legion Meat Draw</h1>
      <br />
      <p>
        Our popular weekly Meat Draws are held each Saturday from 3:00 to 4.30
        pm (except on long weekends), upstairs at the SHOAL Centre (10030
        Resthaven Dr, Sidney). There are 5 draws for Thrifty's giftcards and a
        bonus draw for 5 local shops. We also have a 50/50 draw. All are
        welcome.
      </p>
      <br />
      <p>
        The Meat Draws are our main fund-raiser, not for supporting our Branch
        but for raising funds to support local community and youth
        organizations.
      </p>
      <br />

      <div >
        <p>February 17, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Family Day Weekend
        </p>
        <p>March 30, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Easter Weekend
        </p>
        <p>May 18, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Victoria Day Weekend
        </p>
        <p>July 1, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Canada Day Weekend
        </p>
        <p>August 31, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Labour Day Weekend
        </p>
        <p>September 28, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for National Day for Truth and Reconcilliation Day Weekend
        </p>
        <p>October 12, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          <span ></span>
          Cancelled for Thanksgiving Day Weekend
        </p>
        <p>November 11, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Remembrance Day Weekend
        </p>
        <p>December 28, 2024</p>
        <p style={{ paddingLeft: 50 }}>
          Cancelled for Christmas break
        </p>
      </div>
    </div>
  );
};

export default Meatdraw;
