import React from "react";

const Branch = () => {
  return (
    <div className="">

		<h1 className="text-rose-700">Welcome To Our Branch</h1>
<br />
		<p>Our Branch serves Veterans and Communities throughout the beautiful Saanich Peninsula, on southern Vancouver Island in British Columbia. Chartered in 1926.
    </p>
	 <p> Thank you to everyone who contributed to our 2023 Poppy Campaign.
    </p>
     <br /> 
			
			<p>
			In early 2020, we were obliged to move from the premises that had been our home for some 90 years. The Branch is now in a period of transition. Our General and other meetings continue, and our services are being maintained as fully as possible. 
      We are using the Shoal Centre for our Events but make sure to check the Events page for the location details.
		  <br /><br />Please bear with us as we search for a new more permanent home. We encourage you to check this website frequently for events, as details, timings, and locations may change unexpectedly. 
</p>
			
	</div>


  );
};

export default Branch; 