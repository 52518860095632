import React from "react";

const Executive = () => {
  return(
    <div className="">
      <h1 className="text-rose-700">Executive Data</h1>
<br />
		<p> This page is under further development.</p>
    </div>
    );
};

export default Executive;