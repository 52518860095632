import React from "react";

const Membership = () => {
  return (
    <div className="">
      <h1 className="text-rose-700">Membership</h1>
      <br />
      <p>
        The Legion's primary objectives are to support our Veterans, those who
        served, their dependents and communities, and to remember the Fallen.
        Join us now and help us work to improve the lives of those who have
        given much for us, and to remember and honour those who gave their all.
      </p>
      <br />
      <p>
        Any Canadian citizen or citizen of an Allied nation who is 18 years of
        age or older is welcome to become a member of the Legion. For extensive
        information about the Legion and the benefits of membership, we invite
        you to visit{" "}
        <a
          href="https://www.legion.ca/news/articles/2019/01/08/why-join-the-legion"
          target="blank"
        >
          the Legion's National website.
        </a>
      </p>
      <br />
      <p>
        Come visit our Branch and become a Legion member. Application forms can
        be picked up at a Branch General Meeting. Alternatively, you can
        download the{" "}
        <a
          href="https://docs.wixstatic.com/ugd/6f71c7_68771f7b0f284d4aa0d4d6f5d81b6e5e.pdf"
          target="blank"
        >
          Membership Form
        </a>
        , print and complete it, and bring it into the Branch with your payment.{" "}
      </p>
      <br />
      <p>
        You can also join{" "}
        <a
          className="text-rose-700"
          href="https://portal.legion.ca/join"
          target="blank"
        >
          on-line
        </a>
        .{" "}
      </p>

      <br />
      <h2 className="text-rose-700">Membership</h2>
      <br />
      <p>
        Our Membership Chair will be a the Poppy Office to process membership
        dues or distirbute the membership stickers on October 28 from 1:30 -
        4:00pm, October 30 from 10:30 - 1:00pm, and November 1 from 10:30 -
        1:00pm.
      </p>
      <br />
    </div>
  );
};

export default Membership;
