import "./App.css";
//import Header from './components/Header';
import BBQPoster from "./assets/BBQ.jpg";

const App = () => {
  return (
    <div className="">
      <h1 className="text-rose-700">
        Welcome To the Peninsula Legion Branch 37
      </h1>
      <br />
      <p>
        Our Branch serves Veterans and Communities throughout the beautiful
        Saanich Peninsula, on southern Vancouver Island in British Columbia.
        Chartered in 1926.
      </p>
      <p> Thank you to everyone who contributed to our 2023 Poppy Campaign.</p>
      <br />

      <p>
        In early 2020, we were obliged to move from the premises that had been
        our home for some 90 years. The Branch is now in a period of transition.
        Our General and other meetings continue, and our services are being
        maintained as fully as possible. We are using the Shoal Centre for our
        Events but make sure to check the Events page for the location details.
        <br />
        <br />
        Please bear with us as we search for a new more permanent home. We
        encourage you to check this website frequently for events, as details,
        timings, and locations may change unexpectedly.
      </p>

      <div>
      <br />
      <br />
      <div className="">
        <h2 className="text-lg font-extrabold text-red-700">Rememberance Day Ceremony</h2>
        <br />
        <p>
          On November 11, the traditional Remembrance Day Parade will proceed at
          10.30 am from the Mary Winspear Centre, along Beacon Ave and Second
          Street, to the Cenotaph on Sidney Avenue. The Service of Remembrance
          for the Fallen will commence at 10.50 am. A Fly Past is planned during
          the Two-Minute Silence at 11.00 am. If you are a Veteran and you wish
          to be in the Parade, please muster at the Mary Winspear Centre at
          10.00 am on November 11, or join the Parade at the Cenotaph.
        </p>
        <br />
        <p>
          Thanks to Radio Sidney, the Ceremony will be live-streamed at
          www.radiosidney.ca, starting at 10.30 am.
        </p>
        <br />
        <p>
          NOTE: On November 11, temporary street closures will occur on Beacon
          and Second between 10.15 am and 10.45 am, and on Sidney, Third,
          Fourth, and Mount Baker between 10 am and 12 noon. For more
          information: 250-656-2428
        </p>
      </div>

{/*}      <br />
      <div className="flex content-center justify-center">
        <h1>Legion Community BBQ</h1>
      </div>
      <div className="flex content-center justify-center">
        <h2>Saturday, August 24, 2024 4:30pm - 6:30pm</h2>
      </div>
      <div className="flex content-center justify-center">
        <h2>Centennial Park 7526 Wallace Drive, Saanichton, BC V8M 1V8</h2>
      </div>
      <br />
      <div className="flex content-center justify-center">
      <a
          href="https://www.eventbrite.ca/e/legion-community-bbq-tickets-985166739237?aff=oddtdtcreator"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-white"
        >
          <button class="bg-rose-700 hover:bg-rose-300 text-white font-bold py-2 px-4 rounded-full">
            Buy tickets by clicking here
          </button>
        </a>
        <br />
        <br />
      </div>
      <br />
      <div className="flex content-center justify-center">
        <img src={BBQPoster} alt="logo" style={{}} />
        </div>
        <div>
        <br />
        <p>You're invited to our first annual Legion Community BBQ! Our Legion Community BBQ is the perfect opportunity to enjoy delicious food, meet new friends, and support our local community. 
Bring your family and friends for a day of tasty BBQ Burgers & Hot dogs, beverages, music, and more! Let's celebrate our community spirit together by supporting your local Legion!</p>
<br />
<p>Don't miss out on this fantastic event - mark your calendars and spread the word! We can't wait to see you there!</p>

        
      </div>
      <br />
      <div className="flex content-center justify-center">
        <a
          href="https://www.eventbrite.ca/e/legion-community-bbq-tickets-985166739237?aff=oddtdtcreator"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-white"
        >
          <button class="bg-rose-700 hover:bg-rose-300 text-white font-bold py-2 px-4 rounded-full">
            Buy tickets by clicking here
          </button>
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
{*/}
    </div>

    </div>
  );
};

export default App;
