import React from "react";
import DDayPoster from "../assets/D-Day1.jpg";

const DDay = () => {
  return (
    <div>
      <div className="flex content-center justify-center">
        <a
          href="https://www.eventbrite.ca/e/d-day-hangar-dinner-dance-tickets-886476975497?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-white"
        >
          <button class="bg-rose-700 hover:bg-rose-300 text-white font-bold py-2 px-4 rounded-full">
            Buy tickets by clicking here
          </button>
        </a>
        <br />
        <br />
      </div>
      <br />
      <div className="flex content-center justify-center">
        <img src={DDayPoster} alt="logo" style={{}} />
      </div>
      <br />
      <div className="flex content-center justify-center">
        <a
          href="https://www.eventbrite.ca/e/d-day-hangar-dinner-dance-tickets-886476975497?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-white"
        >
          <button class="bg-rose-700 hover:bg-rose-300 text-white font-bold py-2 px-4 rounded-full">
            Buy tickets by clicking here
          </button>
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default DDay;
