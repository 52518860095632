import React from "react";
import BursaryReg from"../assets/BursaryRegulationsandInformation.pdf"
import BursaryApp from "../assets/BURSARY APPLICATION.pdf"
import AlanCalvert from "../assets/AlanCalvert1.pdf"

const Bursaries = () => {
  return (
    <div className=" m-4 p-4">
      <h1 className="text-rose-700">Youth Bursaries and Scholarships</h1>
      
      <br />
      <p>Each year, the Legion offers Bursaries and two Scholarships to eligible students to assist them in their pursuits of higher education. For detailed information, please contact the Branch office.</p>
      <br />
      <p><strong>The Sir Percy Lake Scholarship</strong> is for $2,000.00, and is based solely on grades and community service. The Scholarship is for a student going into first year at the University of Victoria. Completed <a href="sirpercylake.pdf" target="blank">application forms</a> must be at our Branch 37 office by <strong>June 15th</strong>. Our Branch will select the application with highest grades and community service, which will then be forwarded to our Zone. From all applications within the South Vancouver Island Zone, the applicant with the highest grades and community service will be awarded the scholarship.</p>
      <br />
      <p><strong>The Alan Calvert Scholarship</strong> is for $2,500.00, and is based also on grades and community service. This scholarship is only for our Branch. Completed <a href="AlanCalvert1.pdf" target="blank">application forms </a>must be at our Branch 37 office by <strong>June 30th</strong>. The applicant with the highest grades and community service will be awarded the scholarship.</p>
      <br />
      <p><strong>The Bursary</strong> is awarded based solely on financial need, and we will present as many bursaries as we are financially able to do (usually several are awarded each year). The amount for the bursary is $1,500.00 for University and $1,000.00 for College. For details regarding the assessment, please contact the Branch office. 
      </p>
      <p>
        <br />
        <a className="text-rose-700" href={BursaryReg} target="blank">Bursary Regulations</a>
        </p>
        <p>
        <br />
        <a className="text-rose-700" href={BursaryApp} target="blank">Bursary Application Form</a>
        </p>
        <p>
        <br />
        <a className="text-rose-700" href={AlanCalvert} target="blank">Alan Calvert Scholarship Application Form</a>
        </p>
        </div >
  );
};

export default Bursaries;