import React from "react";

const Contact = () => {
  return (
    <div id="content" className=" m-5 p-5 flex-col">
      <h1 className="text-rose-700">
        Contact Information
      </h1>
      <br />
        <div className="flex-row">
          <div className="grid grid-cols-7">
            <div className="font-bold">
              Telephone:
            </div>
            <div className="col-span-2 h-14 text-rose-700">
              <a href="tel:2506562428">250-656-2428</a>
            </div>
            <div className="col-span-4">

            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="grid grid-cols-7">
            <div className="font-bold">General E-mail:
            </div>
            <div className="col-span-2 h-14 text-rose-700">
              <a href="mailto:rcl37@peninsulalegion.ca">
                rcl37@peninsulalegion.ca
              </a>
            </div>
            <div className="col-span-4">
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="grid grid-cols-7">
            <div className="font-bold">Poppy E-mail:
            </div>
            <div className="col-span-2 h-14 text-rose-700">
              <a href="mailto:poppy@peninsulalegion.ca">
                poppy@peninsulalegion.ca
              </a>
            </div>
            <div className="col-span-4">
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="grid grid-cols-7">
            <div className="font-bold">Postal Address:
            </div>
            <div className="col-span-2 ">
              <p>
                Royal Canadian Legion Branch #37

              </p>
              <p>
                P.O. Box 2152 STN MAIN

              </p>
              <p>
                Sidney, British Columbia V8L 3S6

              </p>
              <br />
            </div>
            <div className="col-span-4">

            </div>
          </div>
        </div>
        <div claclassNamess="flex-row">
          <p>
            For questions concerning the web site, send e-mails to the
            <span> </span>
            <a className="text-rose-700" href="mailto:dej.register@gmail.com">
              Branch #37 Webmaster
            </a>
            .
          </p>
        </div>
    </div>
);
};
export default Contact;