import React from "react";
import BBQPoster from "../assets/BBQ.jpg";

const BBQ = () => {
  return (
    <div>
      <div className="flex content-center justify-center">
        <a
          href="https://www.eventbrite.ca/e/legion-community-bbq-tickets-985166739237?aff=oddtdtcreator"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-white"
        >
          <button class="bg-rose-700 hover:bg-rose-300 text-white font-bold py-2 px-4 rounded-full">
            Buy tickets by clicking here
          </button>
        </a>
        <br />
        <br />
      </div>
      <br />
      <div className="flex content-center justify-center">
        <img src={BBQPoster} alt="logo" style={{}} />
        </div>
        <div>
        <br />
        <p>You're invited to our first annual Legion Community BBQ! Our Legion Community BBQ is the perfect opportunity to enjoy delicious food, meet new friends, and support our local community. 
Bring your family and friends for a day of tasty BBQ Burgers & Hot dogs, beverages, music, and more! Let's celebrate our community spirit together by supporting your local Legion!</p>
<br />
<p>Don't miss out on this fantastic event - mark your calendars and spread the word! We can't wait to see you there!</p>
<br />
        
      </div>
      <div className="flex content-center justify-center">
        <h1>Legion Community BBQ</h1>
      </div>
      <div className="flex content-center justify-center">
        <h2>Saturday, August 24, 2024 4:30pm - 6:30pm</h2>
      </div>
      <div className="flex content-center justify-center">
        <h2>Centennial Park 7526 Wallace Drive, Saanichton, BC V8M 1V8</h2>
      </div>

      <br />
      <div className="flex content-center justify-center">
        <a
          href="https://www.eventbrite.ca/e/legion-community-bbq-tickets-985166739237?aff=oddtdtcreator"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-white"
        >
          <button class="bg-rose-700 hover:bg-rose-300 text-white font-bold py-2 px-4 rounded-full">
            Buy tickets by clicking here
          </button>
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default BBQ;
